.sign-up {
  padding: 50px 0;
  @include flexCC;
  height: auto;

  &-modal {
    width: 90%;
    margin: auto;
    padding: $mobileBodyPadding;

    h1 {
      font-size: 2rem;
      line-height: 1.27;
      font-weight: 300;
      margin-top: 10px;
    }
  }

  &-form {
    .form-control {
      position: relative;
      width: 100%;
      margin: 40px 0;

      label {
        color: $lightText;
        font-weight: 400;
      }

      .password-container {
        display: flex;
        align-items: center;
        background: #fff;
        margin-top: 10px;
        padding-right: 10px;

        input {
          margin-top: 0;
          border: none;
        }

        p {
          font-size: 1.5rem;
          color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }

      select {
        width: 100%;
        margin-top: 10px;
        height: 50px;
        padding: 0 0.5rem;
        background-color: #fff;
        border: none;
        font-family: 'Roboto Condensed', sans-serif;
        color: $darkText;
        border-radius: 0;
      }
    }

    .registration-error {
      color: #ea435c;
    }

    .conditions {
      .terms-condition {
        display: flex;
        margin: 15px 0;

        label {
          margin-left: 15px;
        }
      }

      a {
        text-decoration: underline;
      }
    }

    .account-submitBtn {
      display: flex;
      flex-direction: column-reverse;
      margin-top: 10px;

      p {
        text-decoration: underline;
        cursor: pointer;
        margin-top: 5px;
        text-align: center;
        margin-top: 25px;
      }

      .btn {
        @include flexCB;
        min-width: 180px;
        position: relative;
        margin: auto;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
}

@include for-phone-only {
  .sign-up {
    &-modal {
      padding: $smallestBodyPadding;

      h1 {
        font-size: 1.5rem;
      }
    }
  }
}

@include for-tablet-potrait-up {
  .sign-up {
    &-modal {
      width: 70%;

      h1 {
        font-size: 2.5rem;
      }
    }

    &-form {
      .account-submitBtn {
        margin-top: 30px;
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .sign-up {
    &-modal {
      width: 490px;
    }

    &-form {
      .account-submitBtn {
        @include flexCB;
        flex-direction: row;

        .btn {
          width: auto;
        }

        p {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
