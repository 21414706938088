.successful-registration,
.successful-reset,
.withdrawal-request,
.verify-account,
.notification,
.update-info {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  @include flexCC;
  padding: $mobileBodyPadding;
  z-index: 66;
  overflow-y: scroll;
  padding-top: 10rem;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .modal {
    background: $darkBg;
    color: #ffffff;
    height: auto;
    padding: 40px 20px;
    @include flexCC;
    flex-direction: column;
    border-radius: 10px;
    text-align: center;
    margin: 2rem 0;

    .img {
      position: relative;
      height: 100px;
      width: 100px;
    }

    h2 {
      font-size: 20px;
      margin: 20px;
      color: $primaryColor;
    }

    p {
      font-size: clamp(0.9rem, 3vw, 1rem);
    }

    .seed-phrase {
      border: 1px solid $primaryColor;
      padding: 20px 10px;
      margin: 20px 0;
      width: 100%;
      @include flexCC;
      position: relative;
    }

    button {
      width: 140px;
      margin-top: 1rem;
    }

    .warning {
      color: #ea435c;
    }
  }
}

.withdrawal-request {
  .warning {
    background: #ea435c;
    padding: 1rem;
    color: #ffff !important;
  }

  p {
    line-height: 28px;
    opacity: 0.7;
    font-weight: 400;
  }
}

.update-info {
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .modal {
    align-items: flex-start;
    text-align: start;
    width: 100%;
    position: relative;

    .title-close_btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .close-btn {
        font-size: 1.5rem;
        color: #ea435c;
        cursor: pointer;
      }
    }

    form {
      width: 100%;
      margin-top: 10px;

      .form-control {
        margin-bottom: 10px;

        .password-container {
          display: flex;
          align-items: center;
          background: #fff;
          margin-top: 10px;
          padding-right: 10px;

          input {
            margin-top: 0;
            border: none;
          }

          p {
            font-size: 1.5rem;
            color: black;
          }
        }

        select {
          width: 100%;
          margin-top: 10px;
          height: 50px;
          padding: 0 0.5rem;
          background-color: #fff;
          border: none;
          font-family: 'Roboto Condensed', sans-serif;
          color: $darkText;
          border-radius: 0;
        }
      }

      .submit-btn {
        @include flexCB;
        min-width: 180px;
        position: relative;
        margin: auto;
        width: 100%;
        margin-top: 20px;

        button {
          width: 100%;
          margin-top: 0;
        }
      }
    }

    h2 {
      text-align: center;
      margin: 10px auto;
    }
  }
}

@include for-phone-only {
  .successful-registration {
    padding: $smallestBodyPadding;

    .modal {
      padding: 20px 20px;

      .img {
        height: 50px;
        width: 50px;
      }

      h2 {
        font-size: 20px;
        margin: 10px 0;
      }
    }
  }
}

@include for-tablet-potrait-up {
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .successful-registration,
  .successful-reset,
  .withdrawal-request,
  .verify-account,
  .notification {
    .modal {
      max-width: 500px;

      button {
        width: auto;
        padding: 10px 40px;
      }
    }
  }

  .update-info {
    .modal {
      width: 30%;
    }
  }
}

@include for-big-desktop-up {
}
