.home {
  // STYLES FOR HEADER SECTION
  header {
    background-image: url(../assets/web-background-image.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 16px;
    background-color: #0b0e11;

    .top {
      @include flexCB;
      flex-direction: column;
      padding: 40px 0;

      .left {
        width: 100%;

        h1 {
          font-size: 32px;
        }

        .trade-for-free {
          @include flexC;
          margin: 20px 0;

          .icon {
            color: $primaryColor;
            margin-right: 10px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
          }
        }

        .link {
          display: flex;
          width: 100%;
        }

        button {
          width: 100%;
          font-size: 16px;
          line-height: 24px;
          @include flexCC;
          font-weight: 600;

          span {
            font-size: 1rem;
            @include flexCC;
            margin-right: 5px;
          }
        }

        .active-user {
          @include flexCC;
          width: 100%;
          margin: 30px 0;

          span {
            width: 33%;
            height: 2px;
            background: #474d57;
          }

          p {
            margin: 0 15px;
            font-weight: 500;
          }
        }
      }

      .right {
        width: 100%;
      }
    }

    .bottom {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      margin-top: 20px;

      .info {
        h3 {
          font-weight: 600;
          font-size: 24px;
          line-height: 32px;
        }

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }

  // STYLES FOR INVESTMENTS
  .investments {
    padding: 3rem 14px 5rem 14px;
    @include flex(center, center, column);

    .title {
      text-align: center;
      margin-bottom: 2.5rem;

      h4 {
        font-size: clamp(0.9rem, 3vw, 1rem);
        color: $primaryColor;
      }

      h2 {
        font-size: clamp(1.4rem, 4vw, 2.5rem);
      }
    }

    .grid {
      @include grid(1, 1fr, 30px);

      .card {
        padding: 1.5rem 1.5rem 0 1.5rem;
        background-color: #0b0e11;
        border-radius: 10px;

        h2 {
          font-size: clamp(1.5rem, 4vw, 2rem);
          margin-bottom: 1rem;
        }

        p {
          font-size: clamp(0.9rem, 3vw, 1rem);
          margin-bottom: 1.5rem;
          opacity: 0.9;
          line-height: 26px;
        }

        .img {
          @include flex(center, center, row);
          max-height: 300px;
        }
      }
    }
  }

  // STYLES FOR POPULAR CRYPTOS SECTION
  .popular-cryptos {
    padding: 40px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    border-radius: 50px 50px 0px 0px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px;
    }

    .table {
      &-header {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        padding: 12px 0;

        h4 {
          text-align: end;
          color: #848e9c;

          &:first-child {
            min-width: 170px;
            text-align: start;
          }

          &:last-child {
            display: none;
          }
        }
      }

      &-content {
        .crypto {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 10px;

          div {
            text-align: end;
            padding: 12px 0;

            &:first-child {
              min-width: 170px;
              text-align: start;
              @include flexC;

              span {
                margin-right: 8px;

                &:last-child {
                  text-transform: uppercase;
                }
              }
            }

            &:last-child {
              display: none;
            }

            .img {
              @include flexCC;

              img {
                width: 25px;
                height: 25px;
              }
            }

            &.rise {
              color: rgb(14, 203, 129);
            }

            &.fall {
              color: rgb(246, 70, 93);
            }
          }
        }
      }
    }

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin-top: 40px;
      margin-bottom: 16px;
    }

    button {
      width: 100%;
    }
  }

  // STYLES FOR TRADE CRYPTO FOR FREE SECTION
  .trade-crypto-for-free {
    @include flexCC;
    flex-direction: column;
    background-color: #281a00;
    padding: 40px 16px;
    border-radius: 10px;
    width: 90%;
    margin: 30px auto;

    .img {
      width: 70%;
      margin: auto;
    }

    .text {
      margin: 20px 0;

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0;
      }

      button {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        width: 100%;
        margin-top: 12px;
      }
    }
  }

  // STYLES FOR TRUSTED CRYPTO PLATFORM SECTION
  .crypto-portfolio,
  .trusted-trading-platform {
    padding: 40px 16px;
    @include flexCB;

    .info {
      width: 100%;

      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-top: 8px;
      }

      .portfolio-img {
        width: 190px;
        height: 383.4px;
        margin: 20px auto;
      }

      .step {
        margin-bottom: 40px;

        img {
          width: 48px;
          height: 48px;
        }

        .info {
          h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 8px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #b7bdc6;
          }
        }
      }

      button {
        width: 100%;
      }
    }

    .img {
      display: none;
    }
  }

  // STYLES FOR EARN DAILY REWARDS SECTION
  .need-help {
    padding: 40px 16px;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
    }

    .options {
      @include flexCC;
      flex-direction: column;

      .help {
        width: 100%;
        padding: 16px 0;

        .img {
          width: 48px;
          height: 48px;
        }

        .info {
          margin-top: 16px;

          h4 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin: 8px 0;
          }

          .prompt {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #f0b90b;
          }
        }
      }
    }
  }

  // STYLES FOR START EARNING SECTION
  .start-earning {
    @include flexCC;
    padding: 40px 16px;
    flex-direction: column;
    background-color: #0b0e11;

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }

    .btn {
      width: 100%;

      button {
        width: 100%;
        margin-top: 24px;
      }
    }
  }

  // STYLES FOR START EARNING SECTION
  .trusted-trading-platform {
    .info {
      .portfolio-img {
        width: 343px;
        height: 192.76px;
      }
    }
  }
}

@include for-phone-only {
  .home {
    // STYLES FOR HEADER SECTION
    header {
      .top {
        .left {
          h1 {
            font-size: 27px;
          }
        }
      }
    }

    // STYLES FOR INVESTMENTS
    .investments {
      padding: 3rem 24px 5rem 24px;
    }
  }
}

@include for-tablet-potrait-up {
  .home {
    // STYLES FOR HEADER SECTION
    header {
      padding: 55px;

      .top {
        flex-direction: column-reverse;

        .left {
          h1 {
            font-size: 40px;
          }
        }
      }

      .bottom {
        margin-top: 80px;

        .info {
          h3 {
            font-size: 40px;
            line-height: 48px;
          }

          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

    // STYLES FOR INVESTMENTS
    .investments {
      padding: 3rem 40px 5rem 40px;

      .grid {
        @include grid(2, 1fr, 30px);
      }
    }

    // STYLES FOR POPULAR CRYPTOS SECTION
    .popular-cryptos {
      padding: 40px 24px;
      font-size: 16px;
      line-height: 24px;

      h2 {
        font-size: 32px;
        line-height: 40px;
      }

      .table {
        &-header {
          grid-template-columns: repeat(4, 1fr);
          padding: 16px 0;

          h4 {
            &:first-child {
              min-width: 250px;
            }

            &:last-child {
              display: block;
            }
          }
        }

        &-content {
          .crypto {
            grid-template-columns: repeat(4, 1fr);

            div {
              padding: 16px 0;

              &:first-child {
                min-width: 250px;
              }

              &:last-child {
                display: block;
              }

              .img {
                img {
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }
      }

      h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 24px;
      }

      button {
        width: 25%;
      }
    }

    // STYLES FOR TRADE CRYPTO FOR FREE SECTION
    .trade-crypto-for-free {
      @include flexCB;
      flex-direction: row;

      .img {
        width: 35%;
      }

      .text {
        width: 60%;
        padding-left: 40px;

        h2 {
          font-size: 32px;
          line-height: 40px;
        }

        button {
          width: 60%;
        }
      }
    }

    // STYLES FOR TRADE CRYPTO FOR FREE SECTION
    .crypto-portfolio,
    .trusted-trading-platform {
      padding: 64px 24px;

      .info {
        h2 {
          font-size: 32px;
          line-height: 40px;
        }

        p {
          font-size: 20px;
          line-height: 28px;
          margin-top: 16px;
        }

        .portfolio-img {
          margin: 40px auto;
        }

        .step {
          margin-bottom: 64px;
          @include flexC;

          img {
            width: 80px;
            height: 80px;
          }

          .info {
            margin-left: 24px;

            h3 {
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }

        button {
          width: 30%;
        }
      }
    }

    // STYLES FOR START EARNING SECTION
    .trusted-trading-platform {
      .info {
        .portfolio-img {
          width: 580px;
          height: 326px;
        }
      }
    }

    // STYLES FOR EARN DAILY REWARDS SECTION
    .need-help {
      padding: 40px 24px;

      h2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
      }

      .options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        .help {
          padding: 24px 0;

          .img {
            width: 64px;
            height: 64px;
          }

          .info {
            h4 {
              font-weight: 600;
              font-size: 24px;
              line-height: 32px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
              margin: 12px 0;
            }

            .prompt {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }
    }

    // STYLES FOR START EARNING SECTION
    .start-earning {
      padding: 40px;

      h2 {
        font-size: 40px;
        line-height: 48px;
      }

      .btn {
        width: 25%;

        button {
          width: 100%;
          margin-top: 40px;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
  .home {
    // STYLES FOR INVESTMENTS
    .investments {
      padding: 3rem 60px 5rem 60px;

      .grid {
        @include grid(3, 1fr, 30px);
      }
    }
  }
}

@include for-desktop-up {
  .home {
    // STYLES FOR HEADER SECTION
    header {
      padding: 75px;

      .top {
        @include flexCB;
        flex-direction: row;

        .left {
          width: 50%;

          h1 {
            font-size: 40px;
          }

          .trade-for-free {
            p {
              font-size: 20px;
            }
          }

          .link {
            display: flex;
            width: 70%;
          }

          button {
            width: 100%;
          }

          .active-user {
            width: 70%;

            span {
              width: 40%;
            }
          }
        }

        .right {
          width: 50%;
        }
      }

      .bottom {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    // STYLES FOR INVESTMENTS
    .investments {
      padding: 3rem 80px 5rem 80px;
    }

    // STYLES FOR POPULAR CRYPTOS SECTION
    .popular-cryptos {
      padding: 75px;

      h2 {
        font-size: 40px;
        line-height: 48px;
      }

      .table {
        &-header {
          h4 {
            &:first-child {
              min-width: 310px;
            }
          }
        }

        &-content {
          .crypto {
            div {
              &:first-child {
                min-width: 310px;
              }
            }
          }
        }
      }

      button {
        width: 15%;
      }
    }

    // STYLES FOR TRADE CRYPTO FOR FREE SECTION
    .trade-crypto-for-free {
      .img {
        width: 30%;
      }

      .text {
        width: 65%;

        button {
          width: 30%;
        }
      }
    }

    // STYLES FOR TRADE CRYPTO FOR FREE SECTION
    .crypto-portfolio,
    .trusted-trading-platform {
      padding: 64px 75px;
      @include flexCB;

      .info {
        width: 50%;

        h2 {
          font-size: 40px;
          line-height: 48px;
        }

        h2 + p {
          margin-bottom: 80px;
        }

        .portfolio-img {
          display: none;
        }

        .step {
          .info {
            width: 100%;
          }
        }
      }

      .img {
        display: block;
        width: 50%;
        @include flexCC;

        img {
          width: 335px;
          height: 676px;
        }
      }
    }

    // STYLES FOR START EARNING SECTION
    .trusted-trading-platform {
      .img {
        display: block;
        width: 50%;
        @include flexCC;

        img {
          width: 588px;
          height: 330.45px;
        }
      }
    }

    // STYLES FOR EARN DAILY REWARDS SECTION
    .need-help {
      padding: 40px 75px;

      h2 {
        font-size: 40px;
        line-height: 48px;
      }

      .options {
        grid-template-columns: repeat(3, 1fr);

        .help {
          padding: 24px 0;

          .info {
            h4 {
              font-size: 20px;
              line-height: 28px;
            }
          }

          p {
            font-size: 14px;
            line-height: 20px;
            margin: 12px 0;
          }

          .prompt {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }

    // STYLES FOR START EARNING SECTION
    .start-earning {
      .btn {
        width: 15%;

        button {
          width: 100%;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
