@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import './variables';
@import './mixins';

// COMPONENTS
@import '../components/navbar/navbar';
@import '../components/footer/footer';
@import '../components/loaders/loaders';
@import '../components/modals/modals';
@import '../components/wallet/wallet';
@import '../components/withdrawal/withdrawal-methods';
@import '../components/Customers-Activities/customers-activities';
@import '../components/Bottom-Nav/bottom-nav';
@import '../components/WhatsappChat/whatsapp-chat';

// PAGES
@import './home';
@import '../pages/plans/plans';
@import '../pages/about/about';
@import '../pages/services/services';
@import '../pages/help-center/help-center';
@import '../pages/sign-in/sign-in';
@import '../pages/sign-up/sign-up';
@import '../pages/terms-of-use/terms-of-use';
@import '../pages/privacy-policy/privacy';
@import '../pages/guides/guides';

// USER DASHBOARD
@import '../pages/dashboard/dashboard';
@import '../pages/dashboard/account';
@import '../pages/dashboard/upgrade-account';
@import '../pages/dashboard/deposit';
@import '../pages/dashboard/history';
@import '../pages/dashboard/withdrawal';
@import '../pages/dashboard/spot-trading';
@import '../pages/dashboard/markets';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
  background-color: $darkBg;
  color: $lightText;
  overflow-x: hidden;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-self: center;
}

.border {
  border: 2px solid red;
}

// buttons

button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
  font-weight: 700;
  padding: 12px 24px;
  cursor: pointer;
  transition: 0.2s linear opacity;
  color: $whiteColor;

  &.gold {
    background: $primaryColor;
    color: #181a20;
    border: none;
  }

  &.grey {
    background: #474d57;
    color: $lightText;
    border: none;
  }

  &:hover {
    opacity: 0.5;
  }
}

// CUSTOM INPUT FIELDS

.form-control {
  label {
    margin-bottom: 10px;
    font-size: 16px;

    &.red {
      color: #f03e3d;
    }
  }

  input {
    margin-top: 10px;
  }
}

.input {
  width: 100%;
  height: 100%;
  border: 1px solid #d0cfcf;
  border-radius: 4px;
  background: $whiteColor;
  padding: 16px;
  font-size: 16px;
  outline: none;
  margin-top: 5px;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $whiteColor inset !important;
}

// RESET INPUT FONT-SIZE FOR MOBILE
@media screen and (max-width: 767px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
}

// COPY TO CLIPBOARD

.copy {
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
  font-size: 1.5rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: $generalTransition;

  &:hover {
    transform: scale(1.2);
  }

  &-btn {
    &.copied {
      color: $primaryColor;
    }
  }
}

@include for-tablet-potrait-up {
  .copy {
    position: absolute;
    right: 5px;
  }
}

.awesome-iframe .onlyBubble #button,
.awesome-iframe .bubbleWithLabel #button {
  bottom: 20px !important;
}
