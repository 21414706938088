.whatsapp-chat {
  position: fixed;
  right: 11px;
  bottom: 59px;
  width: 62px;
  height: 62px;
  z-index: 10;
}

@include for-phone-only {
}

@include for-tablet-potrait-up {
  .whatsapp-chat {
    right: 30px;
    bottom: 59px;
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .whatsapp-chat {
    right: 30px;
    bottom: 30px;
  }
}

@include for-big-desktop-up {
}
