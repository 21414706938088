.bank-withdrawal,
.crypto-withdrawal {
  box-shadow: 0px 0px 1px rgb(0 0 0 / 5%), 0px 4px 32px rgb(58 53 47 / 5%);
  border-radius: 16px;
  padding: 20px 5px;
  border: 1px solid $primaryColor;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;

  h2 {
    font-size: 20px;
    text-align: center;
    color: $primaryColor;
  }

  form {
    padding: 0 10px;

    .form-control {
      position: relative;
      width: 100%;
      margin: 40px 0;
    }

    p {
      color: red;
      margin-bottom: 20px;
    }

    .withdraw-btn {
      button {
        width: 100%;
        padding: 1rem;
        font-size: 0.8rem;
      }
    }
  }

  .fullscreen-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 44;
  }
}

@include for-phone-only {
  .bank-withdrawal,
  .crypto-withdrawal {
    h2 {
      font-size: 16px;
    }
  }
}

@include for-tablet-potrait-up {
  .bank-withdrawal,
  .crypto-withdrawal {
    width: 70%;
    margin: auto;

    h2 {
      font-size: 25px;
    }

    form {
      .withdraw-btn {
        @include flexCC;

        button {
          width: 80%;
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .bank-withdrawal,
  .crypto-withdrawal {
    form {
      .withdraw-btn {
        button {
          width: 100%;
          padding: 1rem;
          font-size: 0.8rem;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
