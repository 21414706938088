.guides {
  h1 {
    font-weight: 600;
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    line-height: 32px;
    margin-bottom: 20px;
  }
  h2 {
    font-weight: 500;
    font-size: clamp(1.3rem, 4vw, 2rem);
    line-height: 32px;
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  p {
    font-size: clamp(1rem, 4vw, 1.2rem);
    color: #b7bdc6;
    line-height: 32px;
    font-weight: 400;
  }

  .block {
    padding: 40px 16px;
    @include flex(flex-start, center, column);
    gap: 1rem;

    h2 {
      font-size: clamp(1.3rem, 5vw, 2rem);
    }

    ol,
    ul {
      padding-left: 24px;
      @include flex(flex-start, center, column);
      gap: 1rem;
      font-size: clamp(0.9rem, 3vw, 1.1rem);
      font-weight: 400;
      color: #b7bdc6;
      padding-right: 400px;
    }

    ul {
      list-style-type: disc;
    }
  }

  .assets {
    padding: 40px 16px;

    &-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 20px;

      .card {
        display: flex;
        border: 1px solid $primaryColor;
        align-items: center;
        border-radius: 12px;
        flex-direction: column;
        padding: 40px 40px;

        .logo {
          height: 120px;
          width: 120px;
          margin-bottom: 20px;
          position: relative;
        }
      }
    }
  }
}

@include for-phone-only {
  .guides {
    .assets {
      &-container {
        .card {
          padding: 40px 20px;

          .logo {
            height: 100px;
            width: 100px;
          }

          h3 {
            padding: 0px 30px;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

@include for-tablet-potrait-up {
  .guides {
    .assets {
      &-container {
        grid-template-columns: repeat(2, 1fr);

        .card {
          min-height: 450px;
          position: relative;

          h3 {
            padding: 0px 20px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include for-tablet-landscape-up {
}

@include for-desktop-up {
  .guides {
    .block {
      padding: 40px 75px;

      p {
        padding-right: 400px;
      }
    }

    .assets {
      padding: $desktopBodyPadding;

      &-container {
        grid-template-columns: repeat(3, 1fr);

        .card {
          min-height: 400px;
        }
      }
    }
  }
}

@include for-big-desktop-up {
}
